<template>
  <div>
    <div>
      <div class="_SelectHeader">
        <div>
          <div class="_Select">
            <span>公司：</span>
            <el-select v-model="value1" clearable placeholder="请选择">
              <el-option
                v-for="item in compyList"
                :key="item.id"
                :label="item.companyName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <el-button icon="el-icon-search" @click="getTableList"></el-button>
        </div>
        <div>
          <div style="margin-left: 10px">
            <el-tooltip
              class="item"
              effect="dark"
              content="导入文件格式为xlsx"
              placement="top"
            >
              <el-button
                class="_ImgBtn"
                type="primary"
                style="margin: 0"
                @click="uploadFile"
                ><img style="margin: 0" src="../../assets/images/import2.png"
              /></el-button>
            </el-tooltip>
          </div>
          <div style="margin-left: 10px">
            <el-button
              class="_ImgBtn"
              type="primary"
              style="margin: 0"
              @click="exportExcel"
              ><img src="../../assets/images/import1.png" style="margin: 0"
            /></el-button>
          </div>
          <el-button type="primary" class="_ImgBtn" @click="operation('新增')">
            <img src="../../assets/images/add2.png" />
            新增过程职能表</el-button
          >
          <el-button
            type="warning"
            v-if="this.tableRowIndex != null"
            icon="el-icon-edit"
            @click="operation('修改')"
            >修改</el-button
          >
          <el-button
            type="danger"
            v-if="tableList.length != 0"
            icon="el-icon-delete"
            @click="deleteItem"
            >批量删除</el-button
          >
        </div>
      </div>
      <input
        type="file"
        @change="importFile(this)"
        id="imFile"
        ref="fileBtn"
        style="display: none"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <div>
        <el-table
          :data="isShow1 ? tableEmptyList : tableList"
          :height="elementHeight"
          style="width: 100%"
          border
          id="table"
          ref="multipleTable"
          @row-click="rowClick"
          :row-class-name="tableRowClassName"
          :row-style="selectedstyle"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="60" v-if="isShow">
          </el-table-column>
          <el-table-column prop="compName" label="公司"> </el-table-column>
          <el-table-column prop="processName" label="过程"> </el-table-column>
          <el-table-column prop="fileNo" label="文件编号"> </el-table-column>
          <el-table-column prop="fileName" label="文件名称"> </el-table-column>
          <el-table-column prop="isUpFile" label="上级公司文件">
            <template slot-scope="scope">
              <span>{{ scope.row.isUpFile == 1 ? "√" : "" }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <Dialog
      :title="title"
      @handleClose="handleClose"
      width="45%"
      top="15vh"
      :showTowText="true"
      @handleReqData="handleReqData"
      :showDialog="showDialog"
    >
      <div class="dialog">
        <div>
          <div class="_Select">
            <span>公司：</span>
            <el-select
              v-model="compyValue"
              clearable
              placeholder="请选择"
              @change="compyDialogInp"
              :disabled="title == '修改'"
            >
              <el-option
                v-for="item in compyList"
                :key="item.id"
                :label="item.companyName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="_Select">
            <span>过程：</span>
            <el-select v-model="processValue" clearable placeholder="请选择">
              <el-option
                v-for="item in processDialogList"
                :key="item.id"
                :label="item.processName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div style="width: 300px">
          <div class="_Inp">
            <span style="text-align: start">文件编号：</span>
            <el-input placeholder="文件编号" v-model="fileNo"> </el-input>
          </div>
          <div class="_Inp">
            <span style="text-align: start">文件名称：</span>
            <el-input placeholder="文件名称" v-model="fileName"> </el-input>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: end;
              margin-top: 15px;
            "
          >
            <span>是否勾选上级公司文件：</span>
            <el-checkbox
              v-model="rowChecked"
              @change="changRowChecked"
            ></el-checkbox>
          </div>
        </div>
      </div>
    </Dialog>
    <Dialog
      title="查看错误"
      :showDialog="showErrorDialog"
      width="80%"
      showTowText="true"
      @handleClose="handleClose2"
      @handleReqData="handleClose2"
      top="1vh"
      class="error-dialog"
    >
      <el-table :data="tableList3" height="545" style="width: 100%" border>
        <el-table-column prop="fileNo" label="文件名" width="320">
        </el-table-column>
        <el-table-column prop="saveTime" label="导入时间" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.saveTime.split("T")[0] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="errorLog" label="错误信息" show-overflow-tooltip>
          <template slot-scope="scope">
            <span @click="clickText(scope.row.errorLog)">{{
              scope.row.errorLog
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </Dialog>
    <Dialog
      title="错误信息"
      :showDialog="showErrorText"
      width="55%"
      showTowText="true"
      @handleClose="showErrorText = false"
      @handleReqData="showErrorText = false"
      top="5vh"
      class="error-dialog"
    >
      <div class="error-text">
        <span>{{ errorText }}</span>
      </div>
    </Dialog>
  </div>
</template>
  
  <script>
import XLSX from "xlsx";
import Dialog from "../../components/dialog/Dialog.vue";
import { get, post, put } from "../../api/http";
import { exportExcel } from "../../modules/ExportExcel";

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      tableList: [],
      tableList2: [],
      elementHeight: 0,
      showDialog: false,
      compyList: this.$store.state.companyList,
      deptDialogList: [],
      postDialogList: [],
      processDialogList: [],
      value1: "",
      compyValue: null,
      processValue: null,
      fileNo: "",
      fileName: "",
      tableRowIndex: null,
      title: "",
      deleteList: [],
      rowItem: {},
      rowChecked: false,
      showErrorDialog: false,
      tableList3: [],
      errorText: "",
      showErrorText: false,
      isShow: true,
      isShow1: false,
      tableEmptyList: [],
    };
  },
  created() {
    this.value1 = JSON.parse(sessionStorage.getItem("userInfo")).upCompanyId;
    this.getTableList();
  },
  mounted() {
    this.imFile = document.getElementById("imFile");
    this.getElementHeight();
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById("table").offsetTop + 104);
      });
    },
    handleClose() {
      this.showDialog = false;
    },
    rowClick(row) {
      this.tableRowIndex = row.index;
      this.rowItem = row;
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          "background-color": "#E2E8FA !important",
          "font-weight": "bold !important",
          color: "#409EFF",
        };
      }
    },
    getTableList() {
      get("/api/ProcessAndFile/GetList?CompId=" + this.value1).then((resp) => {
        if (resp.code == 200) {
          this.tableList = resp.data;
          this.tableList2 = JSON.parse(JSON.stringify(resp.data));
        }
      });
    },
    getProcessList(p) {
      get("/api/Process?CompId=" + p).then((resp) => {
        if (resp.code == 200) {
          this.processDialogList = resp.data;
        }
      });
    },
    // 弹窗下拉框
    compyDialogInp(e) {
      this.processValue = "";
      this.processDialogList = [];
      this.getProcessList(e);
    },
    changRowChecked(e) {
      this.rowChecked = e;
    },
    // 导入excel
    uploadFile: function () {
      this.$confirm("是否需要标准格式文件?", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // this.tableList = [];
          this.isShow1 = true;
          this.isShow = false;
          if (!this.isShow) {
            this.$nextTick(() => {
              exportExcel("#table", "标准格式文件.xlsx");
              this.isShow1 = false;
              this.isShow = true;
            });
          }
        })
        .catch((action) => {
          if (action === "cancel") {
            // 点击导入按钮
            this.imFile.click();
          }
        });
    },
    importFile: function () {
      let obj = this.imFile;
      if (!obj.files) {
        return;
      }
      this.tableList = [];
      var list = [];
      var f = obj.files[0];
      var reader = new FileReader();
      let $this = this;
      reader.onload = function (e) {
        var data = e.target.result;
        if ($this.rABS) {
          $this.wb = XLSX.read(btoa(this.fixdata(data)), {
            // 手动转化
            type: "base64",
          });
        } else {
          $this.wb = XLSX.read(data, {
            type: "binary",
          });
        }
        var excelData = XLSX.utils.sheet_to_json(
          $this.wb.Sheets[$this.wb.SheetNames[0]]
        );
        var stopExecution = false;
        excelData.map((item, index) => {
          if (stopExecution) return;
          if (
            !Object.prototype.hasOwnProperty.call(item, "公司") &&
            index == 0
          ) {
            $this.$message.error("导入文件不符合");
            stopExecution = true;
            return;
          }
          if (!Object.prototype.hasOwnProperty.call(item, "公司")) {
            item.公司 = excelData[index - 1].公司;
          }
          var data = {
            compName: item.公司,
            processName: item.过程 + "",
            fileNo: item.文件编号 + "",
            fileName: item.文件名称 + "",
            isUpFile: item.上级公司文件 ? 1 : 0,
            saveTime: new Date().toISOString(),
            saveUserId: $this.$store.state.userInfo.id + "",
          };
          list.push(data);
          $this.tableList.push(data);
        });
        if (stopExecution) return;
        $this.handleAdd(list);
      };
      if (this.rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },
    handleAdd(list) {
      post("/api/ProcessAndFile/BatchAdd", JSON.stringify(list))
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: this.showDialog ? "新增成功" : "导入成功",
            });
            this.$refs.fileBtn.value = null;
            // 导入后显示
            if (this.showDialog) {
              this.value1 = this.compyValue;
            }
            this.getTableList();
            if (this.showDialog) this.showDialog = false;
          } else {
            this.tableList = [];
            // this.showError(resp.message);   //需改为过程职能的接口，此接口为岗位职能表导入错误的接口，查不到该接口。
            this.$refs.fileBtn.value = null;
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.tableList = [];
          this.$message.error(this.showDialog ? "新增失败" : "导入失败");
        });
    },
    // 导出
    exportExcel() {
      this.isShow = false;
      if (!this.isShow) {
        this.$nextTick(async () => {
          await exportExcel("#table", "过程职能表.xlsx");
          this.isShow = true;
        });
      }
    },
    // 修改、删除操作
    operation(title) {
      this.showDialog = true;
      this.title = title;
      if (title == "修改") {
        this.compyValue = this.rowItem.compangyId;
        this.processValue = this.rowItem.processId;
        this.getProcessList(this.compyValue);
        this.fileNo = this.rowItem.fileNo;
        this.fileName = this.rowItem.fileName;
        this.rowChecked = this.rowItem.isUpFile == 1 ? true : false;
      } else {
        this.compyValue = null;
        this.processValue = null;
        this.fileNo = "";
        this.fileName = "";
        this.rowChecked = false;
      }
    },

    handleReqData() {
      if (!this.processValue || !this.compyValue) {
        this.$message({
          type: "warning",
          message: "公司/过程为空",
        });
        return;
      }
      let f = this.processDialogList.filter((f) => {
        return f.id == this.processValue;
      });
      if (this.title == "新增") {
        let c = this.compyList.filter((c) => {
          return c.id == this.compyValue;
        });
        let list = [
          {
            processId: f[0].id,
            compName: c[0].companyName,
            compangyId: c[0].id,
            processName: f[0].processName,
            fileNo: this.fileNo,
            fileName: this.fileName,
            isUpFile: this.rowChecked ? 1 : 0,
            saveTime: new Date().toISOString(),
            saveUserId: this.$store.state.userInfo.id + "",
          },
        ];
        this.handleAdd(list);
        return;
      }
      var data = {
        id: this.rowItem.id,
        compangyId: this.compyValue,
        processId: this.processValue,
        processName: f[0].processName,
        fileNo: this.fileNo,
        fileName: this.fileName,
        isUpFile: this.rowChecked ? 1 : 0,
        saveTime: new Date().toISOString(),
        saveUserId: this.$store.state.userInfo.id + "",
      };
      put("/api/ProcessAndFile", data).then((resp) => {
        if (resp.code == 200) {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.showDialog = false;
          this.tableRowIndex = null;
          this.getTableList();
        }
      });
    },
    deleteItem() {
      if (this.deleteList.length == 0) {
        this.$message({
          type: "success",
          message: "请勾选列表",
        });
        return;
      }
      this.$confirm("您确认要删除当前所选中的列表？").then(() => {
        var data = [];
        this.deleteList.map((item) => {
          data.push({
            id: item.id,
          });
        });
        post("/api/ProcessAndFile/BatchDelete", JSON.stringify(data))
          .then((resp) => {
            if (resp.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.getTableList();
              this.tableRowIndex = null;
            } else {
              this.$message.error(resp.message);
            }
          })
          .catch(() => {
            this.$message.error("删除失败");
          });
      });
    },
    // 勾选
    handleSelectionChange(val) {
      this.deleteList = val;
      console.log(this.deleteList);
    },
    // 查看错误
    showError(msg) {
      this.$confirm(msg, {
        confirmButtonText: "查看错误",
        cancelButtonText: "关闭",
        type: "error",
      })
        .then(() => {
          get(
            "/api/PostErrorlog/GetByUserId?UserId=" +
              JSON.parse(sessionStorage.getItem("userInfo")).id
          ).then((resp) => {
            if (resp.code == 200) {
              this.tableList3 = resp.data;
            }
          });
          this.showErrorDialog = true;
        })
        .catch(() => {
          location.reload();
        });
    },
    handleClose2() {
      this.showErrorDialog = false;
      location.reload();
    },
    clickText(e) {
      this.errorText = e;
      this.showErrorText = true;
    },
  },
};
</script>
  
  <style lang="less" scoped>
._SelectHeader {
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
  }
}
.operation {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 20px;
}
.dialog {
  display: flex;
  ._Select {
    margin-top: 10px;
  }
  > div:nth-of-type(2) {
    margin-left: 50px;
  }
}
.error-text {
  width: 100%;
  height: 400px;
  overflow: scroll;
}
</style>
  